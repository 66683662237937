<template>
  <div class="download_box">
    <div class="w">
      <div class="down">
        <div class="down_left">
          <img src="../assets/img/home/phone-2.png" alt="" />
        </div>
        <div class="down_right">
          <div class="down_title">云校联盟APP</div>
          <div class="down_tag">
            <div>
              <img src="../assets/img/home/time.png" alt="" />
              创新迎变，智启未来
            </div>
            <div>
              <img src="../assets/img/home/lr.png" alt="" />
              同步辅导，基础培优
            </div>
          </div>
          <div class="down_tag">
            <div>
              <img src="../assets/img/home/kb.png" alt="" />
              技能提升，能力认证
            </div>
            <div>
              <img class="homeImg" src="../assets/img/home/home.png" alt="" />
              陪伴一生的学习好帮手
            </div>
          </div>
          <div class="xiazai_box">
            <div class="ewm">
              <img src="../assets/img/home/ewm.png" alt="" />
            </div>
            <div class="apk_box">
              <div>
                <img src="../assets/img/home/android.png" alt="" />
                安卓版下载
              </div>
              <div>
                <img class="ios" src="../assets/img/home/IOS.png" alt="" />
                IOS暂无下载
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.download_box {
  color: #fff;
  height: 100vh;
  background: url("../assets/img/home/bg_lan_pc.png") no-repeat;
  background-size: cover;
  overflow: hidden;
  .w {
    width: 1200px;
    margin: 0 auto;
    .down {
      display: flex;
      margin-top: 170px;
      .down_left {
        width: 232px;
        height: 478px;
        margin-right: 180px;
      }
      .down_right {
        // width: 800px;
        .down_title {
          font-size: 52px;
          margin: 45px 0;
        }
        .down_tag {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          font-size: 28px;
          img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
            margin-right: 8px;
          }
          .homeImg {
            height: 28px;
          }
          div:nth-child(1) {
            margin-right: 100px;
          }
        }
        .xiazai_box {
          margin-top: 50px;
          display: flex;
          .ewm {
            width: 150px;
            height: 150px;
            background-color: #fff;
            text-align: center;
            margin-right: 50px;
            img {
              width: 135px;
              height: 135px;
              margin: 8px 0;
            }
          }
          .apk_box {
            div {
              padding: 0 25px;
              box-sizing: border-box;
              height: 50px;
              line-height: 50px;
              background-color: #fff;
              margin-top: 20px;
              border-radius: 25px;
              color: #538eeb;
              text-align: center;
              font-size: 18px;
              cursor: pointer;
              img {
                width: 30px;
                height: 30px;
                vertical-align: middle;
                margin-right: 8px;
              }
              .ios {
                width: 35px;
                height: 35px;
                margin-top: -5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>